/* eslint-disable */
import apiClient from '../client';
import {
    GetInvitesRequest,
    EditInviteRequest,
    CreateInviteRequest,
} from '../types';

const inviteService = {
    create: async (params?: CreateInviteRequest): Promise<any> => {
        const response = await apiClient.post<any>('/invites', params);
        return response;
    },

    get: async (data: GetInvitesRequest): Promise<any> => {
        const response = await apiClient.get<any>('/invites', { params: data });
        return response.data;
    },

    answer: async (params: EditInviteRequest): Promise<any> => {
        const response = await apiClient.patch<any>(`/invites/${params.invite_id}`, params);
        return response;
    },
};

export default inviteService;
