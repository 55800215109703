<template>
<div class="chat-view">
    <PtyBack primary @back="goBack" />
    <vs-dialog blur v-model="imgConfirmModal">
        <template #header>
            <h1>{{ $t('Chat.confirmImageHeader') }}</h1>
        </template>

        <vs-row style="width: 100%;" justify="center" direction="row" align="center">
            <img :src="img" style="max-width: 100%; height: auto; max-height: 50vh;" />
        </vs-row>

        <template #footer>
            <vs-row direction="column" style="gap: 1rem">
                <PtyButton id="pty__Chat_discardImage" @click="discardImage" light-blue block>{{ $t('Chat.discardImage') }}</PtyButton>
                <PtyButton id="pty__Chat_confirmImage" @click="sendImage"  block>{{ $t('Chat.confirmImage') }}</PtyButton>
            </vs-row>
        </template>
    </vs-dialog>

    <vs-dialog blur v-model="unlockDrinkFeatureDialog">
        <template #header>
            <h1 style="text-align: center;">{{ $t('Chat.unlockDrinkFeatureDialog.title') }}</h1>
        </template>
        <DrinkIllustration style="width: 100%; height: auto; max-height: 40vh;" />
        <p>
            {{ $t('Chat.unlockDrinkFeatureDialog.description') }}
        </p>

        <template #footer>
            <vs-row direction="column" style="gap: 1rem">
                <PtyButton id="pty__Chat_unlockDrinkFeatureDialog_continue" @click="unlockDrinkFeatureDialog = false" block>{{ $t('Chat.unlockDrinkFeatureDialog.continueChat') }}</PtyButton>
            </vs-row>
        </template>
    </vs-dialog>

    <vs-dialog blur v-model="inviteToDrinkDialog">
        <template #header>
            <h1>{{ $t('Chat.inviteToDrinkDialog.title') }}</h1>
        </template>
        <DrinkIllustration style="width: 100%; height: auto; max-height: 40vh;" />
        <p>
            {{ $t('Chat.inviteToDrinkDialog.description') }}
        </p>

        <template #footer>
            <vs-row direction="column" style="gap: 1rem">
                <!-- yes, no, later -->
                <PtyButton id="pty__Chat_inviteToDrinkDialog_yes" animate extra-shadow @click="inviteToDrink" block>{{ $t('Chat.inviteToDrinkDialog.yes') }}</PtyButton>
                <PtyButton id="pty__Chat_inviteToDrinkDialog_later" light-blue @click="inviteToDrinkDialog = false" block>{{ $t('Chat.inviteToDrinkDialog.later') }}</PtyButton>
                <PtyButton id="pty__Chat_inviteToDrinkDialog_no" danger @click="inviteToDrinkDialog = false" block>{{ $t('Chat.inviteToDrinkDialog.no') }}</PtyButton>
            </vs-row>
        </template>
    </vs-dialog>

    <vs-dialog blur v-model="blockModal">
        <template #header>
            <h1>{{ $t('Chat.somethingWrongHeader') }}</h1>
        </template>

        <p>
            {{ $t('Chat.somethingWrongMessage') }}
        </p>

        <template #footer>
            <vs-row direction="column" style="gap: 1rem">
                <PtyButton id="pty__Chat_cancelButton" @click="blockChat" block>{{ $t('Chat.cancelButton') }}</PtyButton>
                <PtyButton id="pty__Chat_cancelButtonLight" @click="blockModal = false" light-blue block>{{ $t('Chat.cancelButtonLight') }}</PtyButton>
            </vs-row>
        </template>
    </vs-dialog>

    <vs-dialog blur v-model="everythingFineModal">
        <template #header>
            <h1>{{ $t('Chat.howIsItHeader') }}</h1>
        </template>

        <p>
            {{ $t('Chat.howIsItMessage') }}
        </p>

        <template #footer>
            <vs-row direction="column" style="gap: 1rem">
                <PtyButton id="pty__Chat_continueButton" @click="everythingFineModal = false" block>{{ $t('Chat.continueButton') }}</PtyButton>
                <PtyButton id="pty__Chat_stopButton" @click="blockChat" light-blue block>{{ $t('Chat.stopButton') }}</PtyButton>
            </vs-row>
        </template>
    </vs-dialog>
    <div class="action-selection__content">
        <vs-row justify="space-between" align="center" class="inbox" style="width: 100%; padding:0;">
            <vs-avatar circle badge size="80" history style="min-width: 80px; max-width: 25%;" @click="openProfile">
                <img :src="$route.params.participant.pictureUrl" />
                <template #badge>
                    <img :src="tags[$route.params.participant.tag]" style="width: 32px; height: 32px;" />
                </template>
            </vs-avatar>

            <vs-row direction="column" style="max-width: 50%;" align="center" justify="center" class="contact" @click="openProfile">
                <h2 style="position:relative;">
                    {{ $route.params.participant.nickname || 'Unknown' }}
                    <span />
                </h2>
                <p>
                    <template v-if="$route.params.participant.age_of_coparticipant">
                        {{ $route.params.participant.age }} & {{ $route.params.participant.age_of_coparticipant }}
                    </template>
                    <template v-else>
                        {{ $route.params.participant.age }}
                    </template>
                    , {{ $route.params.participant.city || 'Unknown'}}</p>
            </vs-row>

            <vs-row direction="column" style="max-width: 25%;" align="flex-end" justify="center">
                <!--<PtyButton id="pty__Chat_blockUser" transparent @click="blockModal = true">
                    <BlockIcon />
                </PtyButton>-->
                <PtyButton id="pty__Chat_drink" size="md" style="width: 60px; height: 60px;"  v-if="$route.params.matchId != null && $route.params.matchId != ''" :animate="drinkButtonEnabled" :extra-shadow="drinkButtonEnabled" :disabled="!drinkButtonEnabled" @click="inviteToDrinkDialog = true" v-tooltip.bottom="{
                    content: $t('Chat.inviteToDrinkTooltip'),
                    show: inviteToDrinkTooltip,
                    trigger: 'manual'
                }">  
                    <i class='bx bxs-drink bx-sm' style="color: #fff;"></i>
                </PtyButton>
            </vs-row>
        </vs-row>

        <div class="pty-divider">
            <hr />
            <p>{{ $t('Chat.discoverTogether') }}</p>
            <hr />
        </div>


            <PtyButton id="pty__Chat_hotQuiz" v-if="$route.params.matchId != null && $route.params.matchId != '' && questions.length > 0 && !onlyFriends" block no-padding :danger="quizRemainingSeconds < 30" :animate="quizRemainingSeconds > 0" :extra-shadow="quizRemainingSeconds > 0" :disabled="quizRemainingSeconds === 0 || lastAnsweredQuestionId == questions[0].id" @click="openQuiz" dark-red v-tooltip.bottom="{
                    content: $t('Chat.hotQuizTooltip'),
                    show: hotQuizTooltip,
                    trigger: 'manual'
                }"
                >
                <vs-row direction="row" justify="space-between" align="center" style="padding: 5px 10px;">
                    <vs-row direction="column" style="width: 25%;" align="flex-start" justify="center">
                        <img :src="tags['ARDENTE']" style="width: 32px; height: 32px;" />
                    </vs-row>
                    <h2 style="font-weight:700; display: flex; justify-content: center; flex-direction: column;" :style="{'color': quizRemainingSeconds > 0 ? 'white' : 'rgba(var(--vs-danger))'}">
                        {{ $t('Chat.hotQuiz') }}
                        <span style="font-weight: 400;font-size: 0.9rem;" v-if="questions && questions.length > 0 && questions[0].id != lastAnsweredQuestionId">
                            👉 {{$t('Chat.yourTurn')}}
                        </span>
                    </h2>
                    <vs-row direction="column" style="width: 25%;" align="center" justify="center">
                        <!--
                        <small style="font-weight: 300;">{{ $t('Chat.timeRemaining') }}</small>
                        <span>-{{ formatSeconds(quizRemainingSeconds) }}</span>
                        -->
                    </vs-row>
                </vs-row>
            </PtyButton>

        <div class="chat-messages">
            <div class="chat-messages__message" v-for="(message, index) in messages" :key="index" :class="{'sent': message.sent, 'received': !message.sent}">
                <div class="chat-messages__message_top">
                    <div class="chat-messages__message_top__name">
                        {{ message.sender ? message.sender.toUpperCase() : 'UNKNOWN' }}
                    </div>
                    <div class="chat-messages__message_top__time">
                        <ReadIcon v-if="message.sent && message.read_at" />
                        <SentIcon v-else-if="message.sent" /> <!-- 1 tick for sent messages, two ticks for read-->
                        
                        {{ message.time }}
                    </div>
                </div>

                <div class="chat-messages__message_content" :class="{'quiz': message.metadata && message.metadata.type == 'quiz', 'picture': message.attachmentType == 'PICTURE'}" @click="handleMessageClick(message)">
                    <template v-if="message.metadata && message.metadata.type">
                        <template v-if="message.metadata.type == 'quiz'">
                            <template v-if="message.sent">
                                ❓ {{ message.metadata.question }}<br/>
                                🔥 {{ message.metadata.answer }}
                            </template>
                            <template v-else>
                                <!-- if metadata.question_id still present in questions means that someone hasnt answered yet, let's show that as blured-->
                                <template v-if="!questions.find(q => q.id == message.metadata.question_id)">
                                    ❓ {{ message.metadata.question }}<br/>
                                    🔥 {{ message.metadata.answer }}
                                </template>
                                <template v-else>
                                    👀 {{message.sender}} {{ $t('Chat.hasAnswered') }} <br/>
                                    🔒 {{$t('Chat.answerToUnlock')}}
                                </template>
                            </template>

                        </template>
                    </template>
                    <img v-else-if="message.attachmentType == 'PICTURE'" :src="message.attachmentUrl" style="width: 100%; height: auto;" />
                    <template v-else>
                        {{ message.content }}
                    </template>
                </div>
            </div>

        </div>

        <div class="chat-input" style="width: 100%;">
            <PtyInput id="pty__zffzf1hcm" placeholder="Scrivi un messaggio..." block v-model="newMessageText" />
            <PtyButton id="pty__rcgkps94p" v-if="newMessageText.length > 0" icon circle style="width: 50px; height: 50px; position: absolute; top: 0px; right: -5px;" no-padding extra-shadow @click="sendMessage" :disabled="!chatId">
                <MessageIcon />
            </PtyButton>
            <PtyButton id="pty__s32kja5ng" v-else icon circle style="width: 50px; height: 50px; position: absolute; top: 0px; right: -5px;" no-padding extra-shadow :disabled="!chatId" @click="uploadPhoto">
                <i class="bx bxs-camera bx-sm"></i>
            </PtyButton>

            <!-- text/plain because of Android 14 WorkAround: without it it wont ask if he wants to use camera to take a pic-->
            <input type="file" style="display: none;" ref="fileInput" @change="fileChanged" accept="image/*,text/plain"/>
        </div>

    </div>
    <div class="ai-helper">
        
        <PtyButton id="pty__fmb7122vl" transparent no-padding @click="getSuggestion" :loading="aiSuggestionLoading" circle v-tooltip.right="{
                content: $t('Chat.aiTooltip'),
                show: aiTooltip,
                trigger: 'manual',
            }"
            >
            <img :src="AIIcon" style="width: 45px; height:auto;" />
        </PtyButton>
        <div class="suggestion" :class="{'hidden': aiSuggestionHidden}">
            {{ aiSuggestion }}
        </div>
        
        <img class="stars" :src="AIStars" style="width: 45px; height:auto;" />
    </div>
</div>
</template>

<script>
// @ts-ignore
import { UUID } from "uuidjs";

// @ts-ignore
import * as Sentry from '@sentry/vue';

import {
    GlobalEventEmitter
} from '@/GlobalEventEmitter';

import {
    chatService,
    fileService,
    inviteService,
} from '@/rest';

import axios from 'axios';
import AIStars from '../assets/images/icons/ai-stars.png';
import AIIcon from '../assets/images/icons/ai.png';

import ArdenteIcon from '../assets/images/tags/ardente.png';
import DevilIcon from '../assets/images/tags/devil.png';
import DiscretoIcon from '../assets/images/tags/discreto.png';
import InnamoratoIcon from '../assets/images/tags/innamorato.png';
import InnocenteIcon from '../assets/images/tags/innocente.png';
import MaliziosoIcon from '../assets/images/tags/malizioso.png';
import QueenIcon from '../assets/images/tags/queen.png';
import RomanticoIcon from '../assets/images/tags/romantico.png';
import SuperdotatoIcon from '../assets/images/tags/superdotato.png';
import TimidoIcon from '../assets/images/tags/timido.png';

import DrinkIllustration from '../assets/images/illustrations/drink.svg';

import SentIcon from '../assets/images/icons/sent.svg';
import ReadIcon from '../assets/images/icons/read.svg';
import MessageIcon from '../assets/images/icons/message.svg';

import PtyButton from '../components/PtyButton.vue';
import PtyBack from '../components/PtyBack.vue';
import PtyInput from '../components/PtyInput.vue';
import participantService from '@/rest/services/partecipant';
export default {
    name: 'Chat',
    components: {
        PtyBack,
        PtyButton,
        PtyInput,
        MessageIcon,
        ReadIcon,
        SentIcon,
        DrinkIllustration
    },
    data: () => ({
        tags: {
            'ARDENTE': ArdenteIcon,
            'DEVIL': DevilIcon,
            'DISCRETO': DiscretoIcon,
            'INNAMORATO': InnamoratoIcon,
            'INNOCENTE': InnocenteIcon,
            'MALIZIOSO': MaliziosoIcon,
            'QUEEN': QueenIcon,
            'ROMANTICO': RomanticoIcon,
            'SUPERDOTATO': SuperdotatoIcon,
            'TIMIDO': TimidoIcon
        },

        AIIcon,
        AIStars,

        quizRemainingSeconds: 90,
        quizRemainingInterval: null,

        blockModal: false,
        everythingFineModal: false,

        messages: [],
        questions: [],

        newMessageText: '',

        aiSuggestionHidden: true,
        aiSuggestion: null,
        aiSuggestionLoading: false,

        chatId: null,

        img: null,
        imgBlob: null,
        imgConfirmModal: false,

        lastAnsweredQuestionId: null,

        hotQuizTooltip: false,
        aiTooltip: false,

        aiTooltipClosingAt: null,
        hotQuizTooltipClosingAt: null,

        unlockDrinkFeatureDialog: false,
        inviteToDrinkDialog: false,
        inviteToDrinkTooltip: false,
        alreadyInvitedToDrink: false,

        onlyFriends: false,
    }),

    props: {
        me: { // participant of logged user
            type: Object,
            required: false
        }
    },

    computed: {
        drinkButtonEnabled(){
            if(this.alreadyInvitedToDrink){
                return false;
            }

            if(this.messages == null || this.messages.length == 0){
                return false;
            }

            if(this.questions.length > 0){
                return false;
            }

            let messagesCount = 0;
            let minMessages = 8; // 8 messages with quizes
            // get timestamp of latest quiz answer in messages
            const lastQuizAnswer = this.messages.filter(m => m.metadata && m.metadata.type == 'quiz').sort((a, b) => b.offset - a.offset)[0];
            if(lastQuizAnswer == null){
                messagesCount = this.messages.length;
                minMessages = 10; // 10 messages without quizes
            } else {
                const index = this.messages.indexOf(lastQuizAnswer);
                // calculate number of messages since last quiz answer
                messagesCount = this.messages.length - index - 1;
            }

            return messagesCount >= minMessages; // if more than 8 messages, enable drink button
        }
    },

    methods: {
        handleInviteAcceptance(invite){
            // the user from this side has accepted an invite with the other user
            // so we need to disable the drink button
            if(invite.sender.id == this.$route.params.participantId){
                this.alreadyInvitedToDrink = true;
                this.inviteToDrinkTooltip = false;
            }
        },

        async inviteToDrink(){
            // close dialog
            this.inviteToDrinkDialog = false;

            // send invite
            const response = await inviteService.create({
                participant_id: this.$route.params.participantId
            });

            if(response.status != 200){
                Sentry.captureMessage('Error inviting to drink ' + response.status);
                return this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Chat.inviteToDrinkError'),
                    color: 'primary', duration: 5000,
                    position: 'top-center'
                });
            }
            this.alreadyInvitedToDrink = true;
            localStorage.setItem('alreadyInvitedToDrink_' + this.$route.params.participantId, 'true');
            // show success message
            this.$vs.notification({
                title: this.$t('Common.success'),
                text: this.$t('Chat.inviteToDrinkSuccess'),
                color: 'primary', duration: 5000,
                position: 'top-center'
            });
        },

        async uploadPhoto(){
            if(window.flutterChannel){
                await new Promise((resolve, reject) => {
                    window.takeSelfieResult = (b64, message) => {
                        if(b64){
                            this.compressImage('data:image/jpeg;base64,' + b64);
                            resolve();
                        } else {
                            Sentry.captureMessage('Can not take selfie: ' + message);
                            reject(message);
                        }
                    }

                    window.flutterChannel.postMessage(JSON.stringify({
                        method: 'takeSelfie'
                    }))

                    setTimeout(() => {
                        Sentry.captureMessage('Timeout on takeSelfie');
                        reject('Timeout');
                    }, 30000);
                });
            } else {
                this.$refs.fileInput.click();
            }
        },

        async handleTooltipClosure(){
            // check how many ms ago the tooltip was closed, if less than 500ms, wait for the diff
            const aiTooltipDiff = this.aiTooltipClosingAt != null ? Date.now() - this.aiTooltipClosingAt : 0;
            const hotQuizTooltipDiff = this.hotQuizTooltipClosingAt != null ? Date.now() - this.hotQuizTooltipClosingAt : 0;

            if(aiTooltipDiff < 500 || hotQuizTooltipDiff < 500){
                this.aiTooltip = false;
                this.hotQuizTooltip = false;
                console.log('Waiting for tooltip closure');
                await new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 500);
                });
            }
        },

        async goBack(){
            await this.handleTooltipClosure();
            this.$router.push({name: 'Chats'})
        },

        async handleMessageReading(){
            // find messages with read_at == null then call chatService.markAsRead, and then set read_at to now
            const unreadMessages = this.messages.filter(m => !m.sent && m.read_at == null);

            if(unreadMessages.length > 0){
                for(const message of unreadMessages){
                    console.log('Marking as read', message);
                    try {
                        const result = await chatService.markAsRead({
                            chatId: this.chatId,
                            offset: message.offset
                        });

                        if(result.status != 200){
                            throw new Error('Error marking as read');
                        }

                        message.read_at = new Date();
                    } catch (error) {
                        Sentry.captureException(error);
                        console.error(error);
                    }
                }
            }

        },

        handleHotQuizTooltip(){
            // check CHATQUIZ_ANSWERED
            const v = localStorage.getItem('CHATQUIZ_TOOLTIPSHOWN');
            if(v == null || v != 'true'){
                localStorage.setItem('CHATQUIZ_TOOLTIPSHOWN', 'true');
                
                setTimeout(() => {
                    this.hotQuizTooltip = true;
                }, 1000);
            }
        },

        handleAiTooltip(){
            // check AI_TOOLTIP
            const v = localStorage.getItem('AI_TOOLTIPSHOWN');
            if(v == null || v != 'true'){
                localStorage.setItem('AI_TOOLTIPSHOWN', 'true');
                
                setTimeout(() => {
                    this.aiTooltip = true;
                }, 3000);
            }
        },

        handleMessageClick(message){
            if(!this.questions || this.questions.length == 0){
                // no questions, return
                return;
            }

            if(message.sent){
                // it's a my message, return
                return;
            }

            if(!message.metadata || !message.metadata.type || message.metadata.type != 'quiz'){
                // not a quiz, return
                return;
            }

            if(this.questions[0].id == null || message.metadata.question_id == null){
                // no question id, return
                return;
            }

            if(this.questions[0].id != message.metadata.question_id){
                // not the first question, return
                return;
            }
            
            this.openQuiz();
        },

        discardImage() {
            this.img = null;
            this.imgConfirmModal = false;

            // reset input
            this.$refs.fileInput.value = '';
        },

        async sendImage() {
            var vm = this;
            const loading = this.$vs.loading({
                type: 'circle'
            });
            try {

                // upload image
                const uploadUrl = await fileService.getSignedUploadURL({
                    scope: 'CHAT',
                    size: vm.imgBlob.size,
                });

                if (uploadUrl == null || uploadUrl.url == null) {
                    throw new Error('Errore durante il caricamento dell\'immagine');
                }

                // upload to s3 using signed url
                const response = await axios.put(uploadUrl.url, vm.imgBlob, {
                    headers: {
                        'Content-Type': vm.imgBlob.type
                    }
                });

                if (response.status != 200) {
                    throw new Error('Errore durante il caricamento dell\'immagine');
                }

                loading.close();

                const id = UUID.generate();

                // send message
                GlobalEventEmitter.$emit('ws-send', JSON.stringify({
                    chat_id: this.chatId,
                    to_participant_id: this.$route.params.participantId,
                    text: '📷 Immagine',
                    attachment_name: uploadUrl.name,
                    attachment_type: vm.imgBlob.type.includes('image') ? 'PICTURE' : vm.imgBlob.type.includes('video') ? 'VIDEO' : null,
                    id,
                }));

                const url = await fileService.getSignedDownloadURL({
                    name: uploadUrl.name
                });

                if(url == null || url.url == null){
                    throw new Error('Errore durante il caricamento dell\'immagine');
                }

                this.messages.push({
                    sender: 'Me',
                    time: (new Date()).toLocaleTimeString('it-IT', {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    sent: true,
                    content: '📷 Immagine',
                    attachmentType: vm.imgBlob.type.includes('image') ? 'PICTURE' : vm.imgBlob.type.includes('video') ? 'VIDEO' : null,
                    attachmentName: uploadUrl.name,
                    attachmentUrl: url.url,
                    id,
                });

            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
                loading.close();
                return this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('OnBoarding.messages.canNotUploadImage'),
                    color: 'primary', duration: 5000,
                    position: 'top-center'
                });
            }

            // close modal
            this.discardImage();
        },

        compressImage(result){
            const img = new Image();
            img.onload = () => {
                // Create a temporary canvas
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set desired dimensions for compression
                const maxWidth = 720; // Example: max width
                const maxHeight = 1280; // Example: max height
                let width = img.width;
                let height = img.height;

                // Maintain aspect ratio
                if (width > maxWidth || height > maxHeight) {
                    if (width / height > maxWidth / maxHeight) {
                        width = maxWidth;
                        height = Math.round(maxWidth * (img.height / img.width));
                    } else {
                        height = maxHeight;
                        width = Math.round(maxHeight * (img.width / img.height));
                    }
                }

                // Set canvas dimensions
                canvas.width = width;
                canvas.height = height;

                // Draw the image on the canvas
                ctx.drawImage(img, 0, 0, width, height);

                // Convert canvas to compressed base64
                this.img = canvas.toDataURL('image/jpeg', 0.9);

                // Convert canvas to compressed Blob
                canvas.toBlob((blob) => {
                    this.imgBlob = blob;
                    this.imgConfirmModal = true;
                }, 'image/jpeg', 0.9);
            };
            img.src = result;
        },

        fileChanged(e) {
            const file = e.target.files[0];
            if (file) {

                // if is not an image, return
                if (!file.type.includes('image')) {
                    // show an error saying that only images are allowed
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('Chat.onlyImagesAllowed'),
                        position: 'top-center',
                        color: 'primary', duration: 5000,
                    });
                    return;
                }

                const reader = new FileReader();
                reader.onload = (event) => {
                    this.compressImage(event.target.result);
                };
                reader.readAsDataURL(file);
            }
        },


        async openProfile() {
            await this.handleTooltipClosure();

            this.$router.push({
                name: 'Profile',
                params: {
                    participant: this.$route.params.participant,
                    participantId: this.$route.params.participantId
                }
            });
        },
        async openQuiz() {
            // be sure all tootlips are closed
            await this.handleTooltipClosure();

            // check if there is a question to answer
            if(this.questions == null || this.questions.length == 0){
                return;
            }

            this.$router.push({
                name: 'ChatQuiz',
                params: {
                    ...this.$route.params,
                    chat_id: this.chatId,
                    questions: this.questions
                }
            });
        },
        formatSeconds(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;

            return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        },

        sendMessage() {
            if (this.newMessageText) {
                const id = UUID.generate();

                GlobalEventEmitter.$emit('ws-send', JSON.stringify({
                    chat_id: this.chatId,
                    to_participant_id: this.$route.params.participantId,
                    text: this.newMessageText,
                    id,
                }));

                this.messages.push({
                    sender: 'Me',
                    time: (new Date()).toLocaleTimeString('it-IT', {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    sent: true,
                    content: this.newMessageText,
                    id
                });

                this.newMessageText = '';

                this.scrollBottom();
            }
        },

        async chatMessageHandler(message) {
            const data = JSON.parse(message.data);

            if (data.chat_id == this.chatId) {

                let urlResponse = null;
                
                if (data.attachment_type == 'PICTURE' || data.attachment_type == 'VIDEO') {
                    urlResponse = await fileService.getSignedDownloadURL({
                        name: data.attachment_name
                    });

                    if (urlResponse == null || urlResponse.url == null) {
                        console.error('Error loading image');
                        return;
                    }
                }

                const metadata = JSON.parse(data.metadata || '{}');

                if(metadata && metadata.type && metadata.type == 'quiz'){
                    await this.getQuestions(); // refresh questions
                }

                this.messages.push({
                    sender: this.$route.params.participant.nickname,
                    time: (new Date()).toLocaleTimeString('it-IT', {
                        hour: '2-digit',
                        minute: '2-digit'
                    }),
                    sent: false,
                    content: data.text,

                    // attachment
                    attachmentType: data.attachment_type,
                    attachmentName: data.attachment_name,
                    attachmentUrl: urlResponse != null ? urlResponse.url : null,
                    metadata,
                    offset: data.offset,
                });


                // in nextTick this.handleMessageReading(); // mark as read
                this.$nextTick(() => {
                    this.handleMessageReading(); // mark as read
                    // scroll to bottom after 0.5s
                    this.scrollBottom();
                });

                
            }
        },

        blockChat() {
            participantService.block(this.$route.params.participantId).then((response) => {
                if (response.status == 200) {
                    this.$router.push({
                        name: 'Chats'
                    });
                } else {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('Chat.blockError'),
                        position: 'top-center',
                        color: 'primary', duration: 5000,
                    });
                }
            }).catch(() => {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Chat.blockError'),
                    position: 'top-center',
                    color: 'primary', duration: 5000,
                });
            }).finally(() => {
                this.blockModal = false;
                this.everythingFineModal = false;
            });
        },

        getMessages() {
            chatService.details(this.chatId).then((response) => {
                this.messages = response.data != null ? response.data.map((message) => {
                    return {
                        sender: message.participant.id == this.$route.params.participantId ? this.$route.params.participant.nickname : 'Me',
                        time: (new Date(message.created_at)).toLocaleTimeString('it-IT', {
                            hour: '2-digit',
                            minute: '2-digit'
                        }),
                        sent: message.participant.id != this.$route.params.participantId,
                        content: message.payload.text,
                        attachmentType: message.payload.attachment_type,
                        attachmentName: message.payload.attachment_name,
                        attachmentUrl: null,
                        metadata: JSON.parse(message.payload.metadata || '{}'),
                        read_at: message.read_at,
                        offset: message.offset,
                    }
                }).reverse() : [];

                // load images
                this.messages.forEach((message) => {
                    if (message.attachmentType == 'PICTURE') {
                        fileService.getSignedDownloadURL({
                            name: message.attachmentName
                        }).then((response) => {
                            message.attachmentUrl = response.url;
                        }).catch(() => {
                            console.error('Error loading image');
                        });
                    }
                });

                this.handleMessageReading(); // mark as read
            }).catch(() => {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Chat.messagesError'),
                    position: 'top-center',
                    color: 'primary', duration: 5000,
                });
            });

            this.scrollBottom();

            
        },

        scrollBottom() {
            // scroll to bottom after 0.5s
            setTimeout(() => {
                const chatMessages = document.querySelector('.chat-messages');
                chatMessages.scrollTop = chatMessages.scrollHeight;
            }, 500);
        },

        getSuggestion() {
            if (this.aiSuggestionLoading) {
                return;
            }

            if (!this.aiSuggestionHidden) {
                this.aiSuggestionHidden = true;
                return;
            }
            this.aiSuggestionLoading = true;
            chatService.suggest(this.chatId).then((response) => {
                this.aiSuggestion = response.suggestion;
                this.aiSuggestionHidden = false;
                this.aiSuggestionLoading = false;
            }).catch(() => {
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('Chat.suggestionError'),
                    position: 'top-center',
                    color: 'primary', duration: 5000,
                });
            });
        },

        showEverythingFineModal() {
            // if is a match, skip
            if(this.$route.params.matchId != null){
                return;
            }

            // is not a match

            // check if already shown
            if(localStorage.getItem(this.$route.params.participantId + '-everything-fine') != null){
                // already shown
                return;
            }

            // if more than 15 messages, show it
            if(this.messages.length >= 15){
                this.everythingFineModal = true;
                localStorage.setItem(this.$route.params.participantId + '-everything-fine', 'true');
            }
        },

        getQuestions() {
            this.lastAnsweredQuestionId = localStorage.getItem(`chat_${this.chatId}_last_question`);

            if(this.chatId == null || this.chatId == ''){
                return;
            }

            chatService.getQuestions({
                chatId: this.chatId,
                limit: 100,
            }).then((response) => {
                let questions = response.data || [];

                // if there are not questions left and the popup was not shown yet, show the unlockDrinkFeatureDialog and store it in localstorage for this chatId
                if(questions.length == 0 && localStorage.getItem(`chat_${this.chatId}_unlockDrinkFeatureDialog`) == null){
                    localStorage.setItem(`chat_${this.chatId}_unlockDrinkFeatureDialog`, '1');
                    this.unlockDrinkFeatureDialog = true;
                }

                this.questions = questions;
            }).catch((error) => {
                console.error(error);
            });
        },

        async feedMessageHandler(message){
            const data = JSON.parse(message.data);
            switch(data.type){
                case 'PARTICIPANT_BLOCKED':
                    // force to go back
                    this.$router.push({
                        name: 'Chats'
                    });
                    break;
                case 'MESSAGE_READ':
                    // find message with offset and set read_at
                    var readMessage = this.messages.find(m => m.offset != 0 && m.offset != undefined && m.offset == data.message.offset);

                    console.log('Read message', readMessage);

                    // if not found try to find by id
                    if(readMessage == null && data.message.id != null){
                        console.log('Trying to find by id', data.message.id);
                        readMessage = this.messages.find(m => m.id == data.message.id);

                        console.log('Read message by id', readMessage);
                    }

                    if(readMessage){
                        readMessage.read_at = new Date();
                    }

                    // trigger a re-render
                    this.messages.__ob__.dep.notify();
                    break;
            }
        },

        handleTooltipClosureEvt(e){
            var vm = this;
            if (!e.target.closest('.vs-tooltip')) {
                vm.aiTooltip = false;
                vm.hotQuizTooltip = false;
                //vm.inviteToDrinkTooltip = false;
            }
        },

        initialLoad(){
            this.getMessages();
            this.getQuestions();
        }
    },

    watch: {
        inviteToDrinkDialog(n){
            if(n){
                localStorage.setItem(`chat_${this.chatId}_inviteToDrinkTooltip`, '1');
                this.inviteToDrinkTooltip = false;
            }
        },

        drinkButtonEnabled(n){
            if(n && !localStorage.getItem(`chat_${this.chatId}_inviteToDrinkTooltip`)){
                this.inviteToDrinkTooltip = true;
            }
        },

        inviteToDrinkTooltip(n){
            if(n){
                // close other tooltips
                this.aiTooltip = false;
                this.hotQuizTooltip = false;
            }
        },

        messages(n) {
            this.showEverythingFineModal();

            // check if there is a quiz answer
            if(n.filter(m => m.metadata && m.metadata.type == 'quiz').length > 0){
                this.handleHotQuizTooltip();
            }
        },

        chatId(n){
            if(n != null && n != ''){
                this.initialLoad();
            }
        },

        aiTooltip(n, o){
            if(n){
                this.hotQuizTooltip = false; // we want to be sure they dont overlap
                this.inviteToDrinkTooltip = false;
            }

            if(!n && o){
                this.aiTooltipClosingAt = Date.now();
            } else {
                this.aiTooltipClosingAt = null;
            }
        },

        hotQuizTooltip(n, o){
            if(n){
                this.aiTooltip = false; // we want to be sure they dont overlap
                this.inviteToDrinkTooltip = false;
            }

            if(!n && o){
                this.hotQuizTooltipClosingAt = Date.now();
            } else {
                this.hotQuizTooltipClosingAt = null;
            }
        }
    },

    mounted() {
        var vm = this;

        // check common interests from ideal_match (from participant and me)
        if(this.me && this.$route.params.participant){
            const commonInterests = this.me.ideal_match.filter(i => this.$route.params.participant.ideal_match.includes(i));
            console.log('Common interests', commonInterests);

            this.onlyFriends = commonInterests.length == 1 && commonInterests[0] == 'AMICI';
        }

        GlobalEventEmitter.$emit('ws-connect');
        GlobalEventEmitter.$on('chat-message', this.chatMessageHandler)
        GlobalEventEmitter.$on('feed-message', this.feedMessageHandler);
        GlobalEventEmitter.$on('handleInviteAcceptance', this.handleInviteAcceptance);

        // chat-connected
        GlobalEventEmitter.$on('chat-connected', this.getQuestions); // we now only need to refresh questions because message are retransmitted

        if (this.$route.params.participantId == undefined || this.$route.params.participantId == '') {
            this.$router.push({
                name: 'Chats'
            });
            return;
        }

        this.alreadyInvitedToDrink = localStorage.getItem('alreadyInvitedToDrink_' + this.$route.params.participantId) == 'true';

        this.chatId = this.$route.params.chatId;
        console.log(this.$route.params);
        if (this.chatId == null || this.chatId == '') {
            chatService.create({
                participant_id: this.$route.params.participantId,
                match_id: this.$route.params.matchId
            }).then((response) => {
                vm.chatId = response.id;
            }).catch((error) => {
                console.error(error);
            });
        }

        

        /*this.quizRemainingInterval = setInterval(() => {
            if (this.quizRemainingSeconds > 0) {
                this.quizRemainingSeconds--;
            } else {
                clearInterval(this.quizRemainingInterval);
            }
        }, 1000);*/

        this.handleAiTooltip();


        document.addEventListener('touchstart', function (e) {
            if (e.target.tagName.toLowerCase() === 'img') { // prevent download on long press
                e.preventDefault();
            }
        });

        // add event listner on click, if target doesnt include vs-tooltip, close tooltip
        document.addEventListener('click', this.handleTooltipClosureEvt);
        
    },

    beforeDestroy() {
        //clearInterval(this.quizRemainingInterval);

        GlobalEventEmitter.$off('chat-message', this.chatMessageHandler);
        GlobalEventEmitter.$off('feed-message', this.feedMessageHandler);
        GlobalEventEmitter.$off('chat-connected', this.getQuestions);

        GlobalEventEmitter.$off('handleInviteAcceptance', this.handleInviteAcceptance);

        document.removeEventListener('click', this.handleTooltipClosureEvt);
    }
}
</script>
<style>

.chat-view img {
    /* prevent download  on long press */
  pointer-events: none;
  -webkit-user-drag: none;
}

</style>
<style scoped>
.contact span {
    width: 10px;
    height: 10px;
    background: #49e062;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: -12px;
}

.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding-top: 1.5rem;
    width: 100%;
    height: calc(100% - 3.25rem);

}

h2 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
    margin: 0;
}

.chat-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 1rem;
    background: white;
}

.chat-messages {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background: white;
    /* fill remaining space */
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    /* smooth scrolling */
    scroll-behavior: smooth;
}

.chat-input {
    width: 100%;
    position: relative;
}

.chat-messages__message {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;
    background: white;
}

.chat-messages__message_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap: 1.5rem;
    background: white;
}

.chat-messages__message.sent {
    align-items: flex-end;
}

.chat-messages__message.received {
    align-items: flex-start;
}

.chat-messages__message_top__name,
.chat-messages__message_top__time {
    color: #AFAFAF;
    font-family: "Sofia Pro";
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 0.9375rem */
    letter-spacing: 0.03125rem;
}

.chat-messages__message_content {
    max-width: 85%;
    /* text must go to next line */
    word-break: break-word;
}


.chat-messages__message.sent .chat-messages__message_content {
    border-radius: 1.25rem 0rem 1.25rem 1.25rem;
    box-shadow: 0px 10px 35px 0px rgba(223, 251, 255, 0.1);
    border: 0.8px solid #EEF0F2;
    background: #FFF;
    padding: 1rem;
    color: black;
    animation: slideFromLeft 0.5s;
}



.chat-messages__message.received .chat-messages__message_content {
    border-radius: 0rem 1.25rem 1.25rem 1.25rem;
    background: var(--Linear, linear-gradient(12deg, #00A9C0 0.95%, rgba(0, 169, 192, 0.63) 101.3%));
    box-shadow: 0px 10px 35px 0px rgba(68, 188, 204, 0.20);
    padding: 1rem;
    color: white;
    animation: slideFromRight 0.5s;
}

.chat-messages .chat-messages__message_content.quiz {
    /* make it more distinguishable with adding a border and shadow */
    border: unset!important;
    box-shadow: 0px 10px 35px 0px rgba(68, 188, 204, 0.20)!important;
    background: rgb(41, 41, 41)!important;
    color: white!important;
}

.chat-messages .chat-messages__message_content.picture {
    /* no padding, the image must fill the entire div, with overflow hidden to round the corners */
    padding: 0!important;
    overflow: hidden;
    border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.ai-helper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: absolute;
    bottom: 120px;
    left: -5px;
    background: white;
    z-index: 500;
    width: 0px;
    transition: 0.2s;
}

/* when shown, set ai-helper     width: calc(100% - 40px); */
.ai-helper:has(.suggestion:not(.hidden)) {
    width: calc(100% - 40px);
    left: 20px;
}

.ai-helper .vs-button {
    position: absolute;
    left: -20px;
    bottom: -25px;
}

.ai-helper .suggestion {
    border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
    border: 2px solid var(--PTY-Gradient, #00A9C0);

    /* Shadow PTY */
    box-shadow: 0px 10px 35px 0px rgba(68, 188, 204, 0.20);
    background: white;
    color: rgba(var(--vs-primary));
    padding: 1rem;
    transition: 0.3s;
    position: absolute;
    left: 8px;
    padding-left: 25px;
}

.ai-helper .suggestion.hidden {
    width: 0px;
    padding: 0;
    overflow: hidden;
    height: 0;
    opacity: 0;
}

.ai-helper .stars {
    position: absolute;
    z-index: 100;
    right: -16px;
    transition: 0.5s;
    bottom: 20px;
}

.ai-helper:has(.suggestion.hidden) .stars {
    position: absolute;
    bottom: 12px;
    left: 6px;
}

</style>
