<template>
<div class="chats-view">
    <PtyBack @back="goBack" />


    <div class="action-selection__content">
        <h1>{{ $t('Invites.titlePart1') }}<span>{{ $t('Invites.titlePart2') }}</span></h1>
        <p>
            {{ $t('Invites.message') }}
        </p>
    </div>
    
    <div class="inboxes">
        <PtyButton transparent block no-padding v-for="invite in invites" :key="invite.id" :disabled="invite.status != 'PENDING' && invite.status != 'MAYBE_LATER'" @click="showInvite(invite)">
            <div class="inbox">
                <vs-avatar circle badge size="80" history style="min-width: 80px;" @click="(e) => { showProfile(invite.participant_from, e) }">
                    <img :src="invite.participant_from.pictureUrl" />
                    <template #badge>
                        <img :src="invite.participant_from.tagIcon" style="width: 32px; height: 32px;" />
                    </template>
                </vs-avatar>
                <div class="right">
                    <div class="inbox__heading">
                        <h3>{{ invite.participant_from.nickname || 'Unknown' }}</h3>
                        <p>
                            {{ new Date(invite.created_at).toLocaleTimeString() }}
                        </p>
                    </div>
                    <div class="inbox__content">
                        <p v-if="invite.status == 'PENDING' || invite.status == 'MAYBE_LATER'">
                            {{ $t('Invites.pending') }}
                        </p>
                        <p v-else-if="invite.status == 'ACCEPTED'">
                            {{ $t('Invites.accepted') }}
                        </p>
                        <p v-else-if="invite.status == 'REFUSED'">
                            {{ $t('Invites.refused') }}
                        </p>
                            <span class="unread" v-if="invite.status == 'PENDING' || invite.status == 'MAYBE_LATER'"></span>
                    </div>
                </div>

            </div>
        </PtyButton>

        <vs-row v-if="invites != null && invites.length == 0" style="width: 100%;" justify="center" align="center">
            <img src="../assets/images/illustrations/participants.png" style="width: 80%; margin-bottom: 10px;"/>
            <h2 style="text-align: center;">
                {{ $t('Chats.noMatchesYet') }}
            </h2>
        </vs-row>
    </div>
</div>
</template>

<script>
// @ts-ignore
import * as Sentry from '@sentry/vue';

import {GlobalEventEmitter} from '@/GlobalEventEmitter';
import {
    fileService,
    inviteService
} from '@/rest';
import ArdenteIcon from '../assets/images/tags/ardente.png';
import DevilIcon from '../assets/images/tags/devil.png';
import DiscretoIcon from '../assets/images/tags/discreto.png';
import InnamoratoIcon from '../assets/images/tags/innamorato.png';
import InnocenteIcon from '../assets/images/tags/innocente.png';
import MaliziosoIcon from '../assets/images/tags/malizioso.png';
import QueenIcon from '../assets/images/tags/queen.png';
import RomanticoIcon from '../assets/images/tags/romantico.png';
import SuperdotatoIcon from '../assets/images/tags/superdotato.png';
import TimidoIcon from '../assets/images/tags/timido.png';


import PtyButton from '../components/PtyButton.vue';
import PtyBack from '../components/PtyBack.vue';
export default {
    name: 'Invites',
    components: {
        PtyButton,
        PtyBack,
    },
    data: () => ({

        tags: {
            'ARDENTE': ArdenteIcon,
            'DEVIL': DevilIcon,
            'DISCRETO': DiscretoIcon,
            'INNAMORATO': InnamoratoIcon,
            'INNOCENTE': InnocenteIcon,
            'MALIZIOSO': MaliziosoIcon,
            'QUEEN': QueenIcon,
            'ROMANTICO': RomanticoIcon,
            'SUPERDOTATO': SuperdotatoIcon,
            'TIMIDO': TimidoIcon
        },

        invites: null,

        invitesBefore: null,
        invitesAfter: null,
        invitesLoading: false,

        
    }),

    methods: {
        showProfile(participant, e) {
            e.stopPropagation();
            this.$router.push({
                name: 'Profile',
                params: {
                    participant: participant,
                    participantId: participant.id

                }
            });
        },
        goBack() {
            this.$router.push({
                name: 'Chats',
            });
        },

        async getInvites() {
            const loading = this.$vs.loading({
                type: 'circle'
            });
            this.invitesLoading = true;
            try {
                const response = await inviteService.get({
                    limit: 10,
                    before: this.invitesBefore,
                    after: this.invitesAfter
                });
                console.log(response);
                let invites = response.data || [];

                for (var i = 0; i < invites.length; i++) {
                    const participant = invites[i].participant_from;
                    // assoc the tag
                    participant.tagIcon = this.tags[participant.tag];
                    if (participant.pictureUrl == null) {
                        try {
                            const dlUrl = await fileService.getSignedDownloadURL({
                                name: participant.id,
                            })
                            participant.pictureUrl = dlUrl.url;
                        } catch (e) {
                            Sentry.captureException(e);
                            console.error(e);
                        }
                    }
                }
                this.invites = invites;
                this.invitesBefore = response.before;
                this.invitesAfter = response.after;
            } catch (e) {
                console.error(e);
                Sentry.captureException(e);
            } finally {
                this.invitesLoading = false;
            }
            loading.close();
        },

        showInvite(invite) {
            GlobalEventEmitter.$emit('show-invite', invite);
        }
    },

    async mounted() {
        this.getInvites();
    },

    created: function() {
        GlobalEventEmitter.$on('refreshInvites', this.getInvites);
    },

    beforeDestroy: function() {
        GlobalEventEmitter.$off('refreshInvites', this.getInvites);
    }



}
</script>

<style>
.inbox {
    padding: 10px 15px;
    width: 100%;
    box-sizing: border-box !important;
}
</style><style scoped>
.chats-view .inboxes .inbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.chats-view .inboxes .inbox>* {
    /* i want all items fill height */
    height: 100%;
}

.chats-view .inboxes {
    width: 100%;
    overflow-y:auto!important;
    padding-top: 1rem;
}

.chats-view .inboxes .inbox .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    gap: 5px;
    flex-grow: 1;
    width: calc(100% - 100px);
}

.chats-view .inboxes .inbox .inbox__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.chats-view .inboxes .inbox .inbox__heading h3 {
    color: #000;
    font-family: "Sofia Pro";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.0375rem;
}

.chats-view .inboxes .inbox .inbox__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    gap: 0.5rem;
}

.chats-view .inboxes .inbox .inbox__content p {
    /* truncate exceeding text */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.chats-view .inboxes .inbox .inbox__content>*,
.chats-view .inboxes .inbox .inbox__heading>*,
.chats-view .inboxes .inbox .inbox_time>* {
    margin: 0 !important;
}

.chats-view .inboxes .inbox .inbox_time {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.5rem;
}

.chats-view .inboxes .inbox span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.chats-view .inboxes .inbox span.unread {
    background-color: rgba(var(--vs-primary));
}

.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 3rem;
    padding-left: 20px;
    padding-right: 20px;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

h1 span {
    color: rgba(var(--vs-primary));
}

p {
    color: #000;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
    margin: 0;
}

.chats-view {
    width: 100%;
    padding: 0 !important;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: white;

}

.chats-view .chats-header {
    background: rgba(var(--vs-primary));
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 0 10px;
    box-sizing: border-box;
    margin-top: 1rem;
}

.chats-view .chats-header .vs-button p {
    color: #fff !important;
    margin-left: 5px;
}

.chats-view .chats-header .vs-button.disabled p,
.chats-view .chats-header .vs-button.disabled svg>* {
    opacity: 0.5;
}
</style>
