<template>
<div class="tutorial full-screen">
    <PtyBack @back="back" />
    <div class="tutorial__content">
        <img src="../../assets/images/splash/5.png" style="width: 100%;" alt="tutorial" />
        <h1>{{ $t('Tutorial.5.title') }}</h1>
        <p style="overflow-y: scroll; flex: 1;">
            {{ $t('Tutorial.5.caption') }}
        </p>
    </div>

    <pty-button class="sticked-bottom" animate extra-shadow block :loading="loading" @click="next">{{ $t('Common.next') }}</pty-button>
</div>
</template>

<script>
import PtyBack from '@/components/PtyBack.vue';
import PtyButton from '@/components/PtyButton.vue';
export default {
    name: 'Tutorial5',
    components: {
        PtyButton,
        PtyBack
    },
    data: () => ({
        loading: false
    }),

    methods: {
        async next() {
            this.$router.push({ name: 'TutorialDrink' });
        },

        back() {
            this.$router.push({ name: 'Tutorial4' });
        }
    }
}
</script>

<style scoped>
.tutorial {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 1.5rem;
    background: #82D5D9;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    

}

.tutorial__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    height: calc(100% - 100px);
}

.tutorial h1 {
    color: #183B3D;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
}

.tutorial p {
    color: #183B3D;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%;
    /* 1.88169rem */
}
</style>
