import business from "./services/business";
import chat from "./services/chat";
import event from "./services/event";
import file from "./services/file";
import match from "./services/match";
import partecipant from "./services/partecipant";
import quiz from "./services/quiz";
import reservation from "./services/reservation";
import table from "./services/table";
import user from "./services/user";
import invite from "./services/invite";


export const userService = user;
export const businessService = business;
export const chatService = chat;
export const eventService = event;
export const fileService = file;
export const matchService = match;
export const partecipantService = partecipant;
export const quizService = quiz;
export const reservationService = reservation;
export const tableService = table;
export const inviteService = invite;

