<template>
  <div class="pty-flip-card-container" ref="flipCard">
  <div class="pty-dark-overlay" v-if="!noBackground" @click="close" ref="darkOverlay"></div>
  <div class="pty-flip-card" >
    <div class="pty-flip-card-inner">
      <div class="pty-flip-card-front">
        <div class="close" @click="close">
          <i class="bx bx-x"/>
        </div>
        <template v-if="maybeLaterShowcase">
          <vs-row direction="row" style="width: 100%;" justify="center" align="center">
            <PtyButton id="pty__Chats_invitesButton" style="width:80px;" animate extra-shadow dark-red transparent>
              <i class="bx bxs-drink"></i>
              <span style="margin-left: 5px;">10+</span>
            </PtyButton>
          </vs-row>
          <p>
            Potrai accedere alla sezione "Inviti" in qualsiasi momento nella schermata delle conversazioni.
          </p>

          <!-- buttons "Ho capito", "Non mostrare più"-->
          <vs-row direction="column" align="center" justify="center" style="gap: 1rem;">
            <PtyButton id="pty__Chat_drink_maybelatershowcase_igotit" size="md" animate extra-shadow style="height: 50px; width: 75%;" @click="close">
                <i class='bx bxs-like bx-sm' style="margin-right: 5px;"></i> Ho capito
            </PtyButton>
            <PtyButton id="pty__Chat_drink_maybelatershowcase_notshowanymore" size="md" danger extra-shadow style="height: 50px; width: 75%;" @click="closeAndDontShow">
                <i class='bx bx-x bx-sm' style="margin-right: 5px;"></i> Non mostrare più
            </PtyButton>
          </vs-row>
        </template>
        <template v-else-if="inviteAcceptedShowcase">
          <vs-row direction="column" align="center" justify="center" style="gap: 1rem; padding: 0 15px;">
            <h1>
              Invito accettato!
            </h1>

            <h2>Hai accettato l'invito!</h2>
            <p>Trovatevi al bancone per un drink 🍸</p>
          </vs-row>

          <vs-row direction="column" align="center" justify="center" style="gap: 1rem;">
            <PtyButton id="pty__Chat_drink_invitesentshowcase_igotit" size="md" animate extra-shadow style="height: 50px; width: 75%;" @click="close">
                <i class='bx bxs-like bx-sm' style="margin-right: 5px;"></i> Ho capito
            </PtyButton>
          </vs-row>

         
          <p>Buona fortuna!</p>
          <span class="emoji">🥳</span>
        </template>
        <template v-else-if="invite.inviteSentShowcase">
          <vs-row direction="column" align="center" justify="center" style="gap: 1rem; padding: 0 15px;">
            <h1>
              Invito inviato!
            </h1>

            <h2>Abbiamo inviato il tuo invito 🍸</h2>
            <p>Attendi che il tuo match accetti l'invito, potrebbe anche decidere di farlo in un secondo momento</p>
          </vs-row>

          <vs-row direction="column" align="center" justify="center" style="gap: 1rem;">
            <PtyButton id="pty__Chat_drink_invitesentshowcase_igotit" size="md" animate extra-shadow style="height: 50px; width: 75%;" @click="close">
                <i class='bx bxs-like bx-sm' style="margin-right: 5px;"></i> Ho capito
            </PtyButton>
          </vs-row>

         
          <p>Potrebbe volerci del tempo</p>
          <span class="emoji">⏳</span>
        </template>
        <template v-else>
          <vs-row direction="column" align="center" justify="center" style="gap: 1rem; padding: 0 15px;">
            <h1>
              {{ invite && invite.sender && invite.sender.nickname ? invite.sender.nickname : 'Unknown'}}
              <p><template v-if="invite && invite.sender && invite.sender.age_of_coparticipant">
                  {{ invite.sender.age }} & {{ invite.sender.age_of_coparticipant }}
              </template>
              <template v-else-if="invite && invite.sender">
                  {{ invite.sender.age }}
              </template>, {{ invite && invite.sender && invite.sender.city ? invite.sender.city : 'Unknown'}}</p>
            </h1>

            <p v-if="invite.status == 'PENDING'">Ti ha invitato a bere qualcosa 🍸</p>
            <template v-else-if="invite.status == 'ACCEPTED'">
              <h2>Ha accettato il tuo invito!</h2>
              <p>Buona fortuna!</p>
            </template>
            <template v-else-if="invite.status == 'REFUSED'">
              <h2>Ha rifiutato il tuo invito</h2>
              <p>Non ti preoccupare, ci saranno altre occasioni!</p>
            </template>
          </vs-row>

          <div class="outer-circle">
              <div class="inner-circle">
                  <vs-avatar circle badge badge-color="primary" size="100" :loading="pictureUrl === ''">
                      <img :src="pictureUrl" />
                      <template #badge>
                          <img :src="tags[invite.sender.tag]" style="width: 32px; height: 32px;" />
                      </template>
                  </vs-avatar>
              </div>
          </div>

          <vs-row direction="column" align="center" justify="center" style="gap: 1rem;" v-if="invite.status == 'PENDING' || invite.status == 'MAYBE_LATER'">
            <PtyButton id="pty__Chat_drink_accept" size="md" animate extra-shadow style="height: 50px; width: 75%;" @click="accept">
                <i class='bx bxs-drink bx-sm' style="margin-right: 5px;"></i> Accetta
            </PtyButton>
            <vs-row direction="row" align="center" justify="space-between" style="gap: 0.5rem; width: 75%;">
              

              <!-- più tardi-->
              <PtyButton id="pty__Chat_drink_later" size="md" light-blue extra-shadow style="height: 50px; width: calc(50% - 0.5rem);" @click="later">
                  <i class='bx bx-time bx-sm' style="margin-right: 5px;"></i> Più tardi
              </PtyButton>

              <PtyButton id="pty__Chat_drink_decline" size="md" danger extra-shadow style="height: 50px; width: calc(50% - 0.5rem);" @click="decline">
                  <i class='bx bx-x bx-sm' style="margin-right: 0px;"></i> Rifiuta
              </PtyButton>
            </vs-row>
          </vs-row>

          <vs-row direction="column" align="center" justify="center" style="gap: 1rem;" v-else-if="invite.status == 'ACCEPTED' || invite.status == 'REFUSED'">
            <PtyButton id="pty__Chat_drink_accept" size="md" animate extra-shadow style="height: 50px; width: 75%;" @click="close">
                <i class='bx bxs-drink bx-sm' style="margin-right: 5px;"></i> Chiudi
            </PtyButton>
          </vs-row>
        
          <template v-if="invite.status == 'PENDING' || invite.status == 'MAYBE_LATER'">
            <p>Premi per iniziare</p>
            <span class="emoji">☝️</span>
          </template>
          <template v-else-if="invite.status == 'ACCEPTED'">
            <p>Trovatevi al bancone per un drink 🍸</p>
            <span class="emoji">🥳</span>
          </template>
          <template v-else-if="invite.status == 'REFUSED'">
            <p>Tocca per chiudere</p>
            <span class="emoji">🥲</span>
          </template>
        </template>
        
        
        
      </div>
      <div class="pty-flip-card-back">
        
        
      </div>
    </div>
  </div>
</div>
</template>

<script>
// @ts-ignore
import * as Sentry from '@sentry/vue';

import { fileService, inviteService } from '@/rest';

import PtyButton from '@/components/PtyButton';

import {GlobalEventEmitter} from '@/GlobalEventEmitter';

import ArdenteIcon from '../assets/images/tags/ardente.png';
import DevilIcon from '../assets/images/tags/devil.png';
import DiscretoIcon from '../assets/images/tags/discreto.png';
import InnamoratoIcon from '../assets/images/tags/innamorato.png';
import InnocenteIcon from '../assets/images/tags/innocente.png';
import MaliziosoIcon from '../assets/images/tags/malizioso.png';
import QueenIcon from '../assets/images/tags/queen.png';
import RomanticoIcon from '../assets/images/tags/romantico.png';
import SuperdotatoIcon from '../assets/images/tags/superdotato.png';
import TimidoIcon from '../assets/images/tags/timido.png';

export default {
  name: 'PtyInvite',
  components: {
    PtyButton
  },

  props: {
    invite: {
      type: Object,
      required: false,
    },
    noBackground: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tags: {
          'ARDENTE': ArdenteIcon,
          'DEVIL': DevilIcon,
          'DISCRETO': DiscretoIcon,
          'INNAMORATO': InnamoratoIcon,
          'INNOCENTE': InnocenteIcon,
          'MALIZIOSO': MaliziosoIcon,
          'QUEEN': QueenIcon,
          'ROMANTICO': RomanticoIcon,
          'SUPERDOTATO': SuperdotatoIcon,
          'TIMIDO': TimidoIcon
      },

      pictureUrl: '',


      maybeLaterShowcase: false,
      inviteAcceptedShowcase: false
    }
  },
  mounted() {
    // Load the picture of the participant
    if(this.invite){
      this.loadPicture();
    }

    this.setHeight();

  // Trigger the flip animation when the component is mounted
  setTimeout(() => {
    this.flipCard();
    this.addShakeEffect();
  }, 100); // Delay to ensure the card has been rendered
},

methods: {
  async setHeight(){
    // set card inner of same height as card-front
    const cardFront = this.$refs.flipCard.querySelector('.pty-flip-card-front');
    const cardInner = this.$refs.flipCard.querySelector('.pty-flip-card-inner');
    cardInner.style.height = `${cardFront.clientHeight}px`;
  },

  async loadPicture(){
    if(!this.invite.sender.id) return;
    const dlUrl = await fileService.getSignedDownloadURL({
        name: this.invite.sender.id,
    })
    this.pictureUrl = dlUrl.url;
  },

  flipCard() {
    const cardInner = this.$refs.flipCard.querySelector('.pty-flip-card-inner');
    const darkOverlay = this.$refs.flipCard.querySelector('.pty-dark-overlay');
    if(darkOverlay){
      darkOverlay.classList.add('active');
    }
    cardInner.style.transform = 'rotateY(359deg)';

    GlobalEventEmitter.$emit('show-drink-confetti');
  },
  addShakeEffect() {
    const cardInner = this.$refs.flipCard.querySelector('.pty-flip-card-inner');
    // Add the class to trigger the floating and shaking effect after flip
    setTimeout(() => {
      cardInner.classList.add('float-shake');
    }, 3000); // Wait for the flip animation to complete before starting shake
  },

  close() {
    const cardInner = this.$refs.flipCard.querySelector('.pty-flip-card-inner');
    // Add the closure class after the shake effect ends
    cardInner.classList.add('float-shake-close');

    const darkOverlay = this.$refs.flipCard.querySelector('.pty-dark-overlay');
    if(darkOverlay){
      darkOverlay.classList.remove('active');
    }

    this.$emit('close');
  },

  async answer(status, close = true) {
    const loading = this.$vs.loading();
    try {
      const result = await inviteService.answer({
        invite_id: this.invite.id,
        status,
      });

      if(result.status != 200) {
        throw new Error('Failed to accept the invite status: ' + result.status);
      }
      loading.close();
      if(close){
        this.close();
      }
      return true;
    } catch (error) {
      this.$vs.notification({
          title: this.$t('Common.error'),
          text: this.$t('Invite.answerError'),
          position: 'top-center',
          color: 'primary', duration: 5000,
      });
      Sentry.captureException(error);
      console.error(error);
    }
    loading.close();
    return false;
  },

  async accept() {
    const r = await this.answer('ACCEPTED', false);
    this.inviteAcceptedShowcase = true;
    localStorage.setItem('alreadyInvitedToDrink_' + this.invite.sender.id, 'true');
    GlobalEventEmitter.$emit('handleInviteAcceptance', this.invite);
    console.log(r);
  },

  async decline() {
    const r = await this.answer('REFUSED');
    console.log(r);
  },

  async later() {
    await this.answer('MAYBE_LATER', false);
    // check if he has pressed "do not show anymore", the value is not set
    if(localStorage.getItem('maybeLaterShowCase_shown') === null){
      this.maybeLaterShowcase = true;
    } else {
      this.close();
    }
  },

  closeAndDontShow() {
    localStorage.setItem('maybeLaterShowCase_shown', 'true');
    this.close();
  }
},
watch: {
  maybeLaterShowcase(){
    // recalc height on next tick
    this.$nextTick(() => {
      this.setHeight();
    });
  },

  inviteAcceptedShowcase(){
    // recalc height on next tick
    this.$nextTick(() => {
      this.setHeight();
    });
  }
}
}
</script>

<style>
.pty-flip-card h1, .pty-flip-card p {
  margin: 0;
}

.pty-flip-card img {
  image-rendering: -webkit-optimize-contrast;
}

.pty-flip-card-container {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}


.pty-dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(2px);
  z-index: 0;
  opacity: 0;
  transition: opacity 1s;
}

.pty-dark-overlay.active {
  opacity: 1;
}

#pty__Chat_drink_later .vs-button__content, #pty__Chat_drink_later .vs-button__content i {
  color: rgba(var(--vs-primary), 1)!important;
}

.pty-flip-card .close {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  font-size: 1.5rem;
  color: #000;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
}

.pty-flip-card {
  background-color: transparent;
  width: 90vw;
  height: auto;
  perspective: 1000px;
  
  border-radius: 20px;
  z-index: 1001;
  
  
  
}

.pty-flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  box-shadow: 0 10px 8px 0 rgba(0,0,0,0.2);
  border-radius: 20px;
  transition: transform 1.3s ease-out;
  z-index: 1001;  /* Ensure the element stays on top */

  overflow: hidden; /* this fixes issue of flickering elements on safari (ios) */
}

.pty-flip-card-front, .pty-flip-card-back {
  position: absolute;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.pty-flip-card-front {
  background-color: #fff;
  color: black;
  background:
        url(../assets/images/illustrations/logos.png) top/130% auto no-repeat,
        linear-gradient(180deg, rgb(210, 251, 253) 20.57%, rgb(255, 255, 255) 053.63%);
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
}

.pty-flip-card-back {
  background-color: #ffffff;
  color: white;
  transform: rotateY(180deg);
  background-image: url(../assets/images/illustrations/logos.png);
  background-size: contain;
  background-position: center;
  height: 100%;

}

.pty-flip-card .vs-avatar__badge {
    background: transparent;
    border: unset !important;
    left: -64px;
    bottom: -10px;
}



.pty-flip-card .vs-avatar__badge {
    background: transparent;
    border: unset !important;
    left: -64px;
    bottom: -10px;
}

.pty-flip-card .outer-circle {
  width: 160px;
  height: 160px;
  flex-wrap: wrap;
}

.pty-flip-card .inner-circle {
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pty-flip-card .outer-circle,
.pty-flip-card .outer-circle>div {
    padding: 1rem;
    background: rgba(36, 181, 201, 0.3);
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 100%;
}

.pty-flip-card .inner-circle {
    background: rgba(36, 181, 201, 0.6) !important;
}

@keyframes floatShake {
  0% {
    transform: rotateY(360deg) translateY(0) rotate(0deg);
  }
  6% {
    transform: rotateY(360deg) translateY(-5px) rotate(-2deg);
  }
  10% {
    transform: rotateY(360deg) translateY(5px) rotate(2deg);
  }
  12% {
    transform: rotateY(360deg) translateY(0) rotate(0deg);
  }
  15% {
    transform: rotateY(360deg) translateY(-5px) rotate(-2deg);
  }
  18% {
    transform: rotateY(360deg) translateY(5px) rotate(2deg);
  }
  25% {
    transform: rotateY(360deg) translateY(0) rotate(0deg);
  }


}

.pty-flip-card-inner.float-shake {
  animation: floatShake 5s ease-in-out infinite;
}

.pty-flip-card span.emoji {
  font-size: 2rem;
  cursor: pointer;
  /* animate the hand emoji */
  animation: hand 5s infinite;
}

@keyframes hand {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes cardClose {
  0% {
    transform: rotateY(180deg) scale(1) translateZ(0); /* Initial state */
    opacity: 1;
  }
  30% {
    transform: rotateY(10deg) scale(0.8) translateY(-30px) translateZ(10px); /* Slight rotation and move up */
    opacity: 0.9;
  }
  60% {
    transform: rotateY(-10deg) scale(0.5) translateY(-70px) translateZ(20px); /* More rotation and move up with z-depth */
    opacity: 0.85;
  }
  100% {
    transform: rotateY(0deg) scale(0) translateY(-150px) translateZ(30px); /* Shrink, move away upwards with more z-depth */
    opacity: 0;
  }
}


.pty-flip-card-inner.float-shake-close {
  animation: floatShake 1.5s ease-in-out infinite, cardClose 1s ease forwards;
}

</style>
